@use '../../../variables' as *;

.language-selector {
    position: relative;
    width: 100%;
}

.language-selector .btn {
    width: 100%;
}

.language-selector .btn .btn-content {
    width: 100%;
    justify-content: flex-start;
}

.language-selector .btn svg {
    width: initial;
    height: initial;
}

.language-selector .dropdown-menu {
    top: auto;
    bottom: 100%;
}

.collapsed .language-selector .dropdown-menu {
    width: auto;
}

// Mobile styles

@media (max-width: $vw-mobile) {
    .language-selector {
        position: inherit;

        .dropdown-menu {
            position: absolute;
            top: 72px;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            padding: var(--spacing-xs, 8px) 0;
            background-color: var(--purple-50);
            border-radius: 0;
            border: none;
            margin: 0;
            min-width: 100%;
            height: 100%;
        
            .dropdown-item {
                height: 48px;
        
                .dropdown-label {
                    font-size: 16px;
                    color: var(--white);
                    font-weight: 700;
                }
            }
        }
    }
}