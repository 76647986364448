@use '../variables' as *;

#control-panel #content-wrapper #content .page-content {
    flex-direction: row;
    align-items: center;
    gap: $spacing-xl;
    padding: $spacing-xl;

    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .conditions-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: $spacing-2xl;
        
        .sensors-wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $spacing-md;
            z-index: 1;

            .sensors-container {
                width: 100%;

                .sensors {
                    display: grid;
                    width: 100%;
                    gap: $spacing-md;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                }
            }
        }
    }

    .controls-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: $spacing-md;
        width: 100%;
        z-index: 1;

        .controls {
            width: 100%;
            border-radius: $radius-md;
            border: 2px solid $color-purple-40;
            background: var(--white, #FFF);
            overflow: auto;
            // min-width: 465px;
        
            .boxed-icon-lights .boxed-icon {
                border: 2px solid rgba($color-black, 0.05);
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);

                &.icon-inactive {
                    border: none;
                }
            }
        }
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    #control-panel #content-wrapper #content {
        align-items: center;

        .page-content {
            max-width: 416px;
            max-height: none;
            flex-direction: column;
            padding: $spacing-lg $spacing-sm $spacing-md;
            gap: $spacing-xl;

            .conditions-wrapper {
                .sensors-wrapper {
                    .sensors-container {
                        width: calc(100% + ($spacing-sm * 2));
                        align-self: flex-start;
                        transform: translateX(-$spacing-sm);
                        overflow: auto;

                        .sensors {
                            display: flex;
                            gap: $spacing-xl;

                            .sensor-block {
                                &:first-of-type {
                                    padding-left: $spacing-sm;
                                }
                        
                                &:last-of-type {
                                    padding-right: $spacing-sm;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}