@use '../../../variables' as *;

.program-file {
    display: flex;
    flex-direction: column;
    flex: 1 0 30%;
    max-width: calc((100% - (16px * 2))/3);
    align-self: stretch;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;

    .program-thumbnail {
        width: 100%;
        min-width: 100%;
        border-radius: var(--radius-sm, 8px);
        border: 2px solid var(--purple-20);
        background: var(--purple-10);
        position: relative;
        height: 0;
        padding-top: calc(9/16 * 100%);
        overflow: hidden;

        .program-thumbnail svg {
            max-height: 72%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 85%;
            width: 100%;
            height: 100%;
        }

        * {
            cursor: pointer;
        }
    }

    .program-description {
        display: flex;
        flex-direction: column;
        gap: $spacing-2xs;

        .program-name {
            color: var(--purple-70, #655DDA);
        }
    }

    .btn {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all $transition-duration-fast $transition-timing-easeout;
    }

    &.active {
        .program-thumbnail {
            border: 2px solid var(--green-40, #18D868);
            background: var(--green-10, #DEFFE1);
        }

        .program-description .program-name{
            color: var(--green-50);
        }

        .btn {
            opacity: 1;
            max-height: 48px;
        }
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    .program-file {
        flex: 1 0 100%;
        max-width: 100%;
    }
}