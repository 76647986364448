@use '../../../variables' as *;

.setting {
    display: flex;
    min-height: 64px;
    flex-direction: column;
    padding: $spacing-sm $spacing-md;
    align-items: stretch;
    justify-content: center;
    gap: $spacing-xs;
    border-radius: $radius-md;
    border: $stroke-width-default $stroke-type-default $color-purple-20;
    background: $color-white;

    .setting-header {
        display: flex;
        align-items: center;
        gap: $spacing-sm;

        .setting-title {
            flex: 1 0 0;
            color: $color-purple-70;
        }
        
        .setting-value {
            color: $color-purple-70;
        }

        .setting-actions {
            display: flex;
            align-items: center;
            gap: $spacing-xs;
        }
    }

    .setting-description {
        color: $color-grey-70;
    }

    &.setting-red {
        border-color: $color-red-20;

        .setting-title {
            color: $color-red-70;
        }
    }
}

@media (max-width: $vw-mobile) {
    .setting {
        padding: $spacing-sm;

        .setting-header {
            gap: $spacing-xs;
        }
    }
}