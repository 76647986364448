// Viewport
// When changing viewport variables, remember to also change them in
// webapp-react/src/components/functions/utils/useWindowWidth.js
$vw-mobile: 800px;
$vw-tablet: 1024px;

// Colors
    // Base
    $color-white: #ffffff;
    $color-grey-10: #ECEEED;
    $color-grey-20: #DCDFDE;
    $color-grey-40: #A1AAA7;
    $color-grey-50: #8C9793;
    $color-grey-70: #66706D;
    $color-grey-90: #3A403E;
    $color-black: #1E322B;

    // Green
    $color-green-10: #DEFFE1;
    $color-green-20: #C7FACC;
    $color-green-40: #18D868;
    $color-green-50: #00B868;
    $color-green-70: #009454;
    $color-green-90: #005229;

    // Purple
    $color-purple-10:#F0F0FF;
    $color-purple-20: #E5E5FF;
    $color-purple-40: #9A93FB;
    $color-purple-50: #7870E6;
    $color-purple-70: #5D55D8;
    $color-purple-90: #3D3973;

    // Red
    $color-red-10: #FFEBEB;
    $color-red-20: #FFDBDB;
    $color-red-40: #FF7070;
    $color-red-50: #E15656;
    $color-red-70: #CE3B3B;
    $color-red-90: #6F2525;

// Spacing
$spacing-none: 0;
$spacing-3xs: 2px;
$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;
$spacing-2xl: 64px;
$spacing-3xl: 96px;

// Height
$height-navbar-mobile: 72px;

// Stroke width
$stroke-width-default: 2px;
$stroke-type-default: solid;

// Radius
$radius-none: 0px;
$radius-xs: 4px;
$radius-sm: 8px;
$radius-md: 16px;
$radius-round: 1000px;

// Opacity
$opacity-disabled: .4;
$opacity-text-placeholder: .6;
$opacity-modal-blanket: .6;

// Transition
    // Duration
    $transition-duration-default: 300ms;
    $transition-duration-fast: 100ms;

    // Timing
    $transition-timing-easeout: ease-out;
    $transition-timing-bounce: cubic-bezier(0.18, 0.89, 0.44, 1.16);