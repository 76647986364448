@use '../variables' as *;

#login #content-wrapper #content {
    .page-content {
        flex-direction: row-reverse;
        padding: var(--spacing-lg, 64px);
        justify-content: center;
        align-items: center;
        gap: var(--spacing-xl, 48px);
        
        .login-logo {
            width: 120px;
            position: absolute;
            top: 40px;
            left: 48px;
        }

        .login-info-wrapper {
            display: flex;
            width: 100%;
            max-width: 350px;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-sm, 16px);

            svg {
                width: 100%;
                max-width: 304px;
                height: auto;
            }
        }

        .login-form-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-lg, 32px);

            h1 {
                display: none;
            }

            .login-form-content {
                display: flex;
                padding: var(--spacing-lg, 32px);
                flex-direction: column;
                align-items: center;
                gap: var(--spacing-lg, 32px);
                align-self: stretch;
                max-width: 330px;
                border-radius: var(--radius-md, 16px);
                border: $stroke-width-default $stroke-type-default var(--purple-40, #9A93FB);
                background: var(--white, #FFF);

                .login-form-top, .login-form-bottom{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--spacing-xs, 8px);
                    align-self: stretch;
                }

                .login-dash {
                    color: var(--purple-70, #5D55D8);
                    font-family: Schibsted Grotesk;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; 
                    letter-spacing: -0.14px;
                }

                .login-label {
                    align-self: stretch;
                    color: var(--purple-70, #5D55D8);
                    font-family: Schibsted Grotesk;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 19.6px */
                    letter-spacing: -0.14px;
                }

                .login-inputs {
                    display: flex;
                    align-items: center;
                    gap: var(--spacing-xs, 8px);
                    align-self: stretch;

                    .login-name-input {
                        height: 40px;
                        width: 100%;
                        border-radius: var(--radius-sm, 8px);
                        border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
                        background: var(--white, #FFF);
                        // max-width: 45%;
                        display: flex;
                        padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
                        align-items: center;
                        gap: var(--spacing-xs, 8px);
                        flex: 1 0 0;
                        color: var(--black, #1E322B);
                        font-family: Schibsted Grotesk;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; 
                        letter-spacing: -0.14px;

                        &::placeholder{
                            opacity: 0.6;
                        }
                    }

                    .login-input {
                        font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
                        font-size: 32px;
                        line-height: 100%;
                        border-radius: var(--radius-sm, 8px);
                        border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
                        background: var(--white, #FFF);
                        display: flex;
                        width: 100%;
                        height: 64px;
                        justify-content: center;
                        align-items: center;
                        gap: var(--spacing-xs, 8px);
                        text-align: center;
                    }

                    input:focus-visible {
                        outline: $color-purple-40 auto $stroke-width-default;
                    }
                }

                .login-submit {
                    display: flex;
                    height: 40px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    border-radius: var(--radius-sm, 8px);
                    background: var(--green-50, #00B868);
                    color: var(--white, #FFF);
                    text-align: center;
                    /* body/small/bold */
                    font-family: Schibsted Grotesk;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 19.6px */
                    letter-spacing: -0.14px;
                }
            }
        }
    }
}

@media (max-width: $vw-mobile) {
    #login {
        #content-wrapper {
            margin-top: -72px;
            justify-content: flex-start;
            
            #content {
                align-items: center;
                min-height: 0;

                .page-content {
                    flex-direction: column;
                    max-width: 416px;
                    padding: var(--spacing-lg, 32px);
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: var(--spacing-lg, 32px);

                    .login-logo {
                        position: inherit;
                    }

                    .login-info-wrapper {
                        max-width: none;

                        h1 {
                            display: none;
                        }

                        svg {
                            max-width: none;
                        }
                    }

                    .login-form-wrapper {
                        max-width: none;

                        h1 {
                            display: inherit;
                        }

                        .login-form-content {
                            max-width: none;
                            padding: $spacing-md;
                        }
                    }
                }
            }
        }
    }
}