@use '../variables' as *;

.action-bar {
    .selection-bar-header {
        width: 100%;
        gap: $spacing-sm;
        overflow: hidden;

        .selection-bar-heading {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.date-period-sections-wrapper{

    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    background: $color-white;
    border-radius: $radius-md;
    border: $stroke-width-default $stroke-type-default $color-purple-40;
    overflow: auto;
}


/* styling the date in the gallery view  */

.date-period-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: -$spacing-2xs;
    overflow: hidden;
    gap: $spacing-sm;
    padding-top: $spacing-lg;

    .date-period-header {
        width: 100%;
        padding: 0 $spacing-lg;
        gap: $spacing-xs;
    }
}

.date-period-title {
    gap: $spacing-xs;
}

.selecting .date-period-title svg,
.selecting .date-period-title h3 {
    cursor: pointer;
} 

.date-period-date{

    display: flex;
    flex-direction: row;
    gap: $spacing-2xs;
    color: $color-purple-70;

    font-family: Schibsted Grotesk;
    font-size: $spacing-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;
}

.date-period-start, .date-period-end{

    color: $color-purple-70;
    align-self: stretch;
    font-family: Schibsted Grotesk;
    font-size: $spacing-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;

}

.gallery-image-wrapper{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-2xs;
    padding: $spacing-sm;
}

.images-container{

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: $spacing-2xs;
    align-self: stretch;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));

}

.image-selection-wrapper {
    position: relative; 
    display: inline-block; 
}

.image-selection-wrapper .image-checkbox{

    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 1;
}


.image-selection-wrapper .image-checkbox svg{

    cursor: pointer;
}

.gallery-grid-keeper {
    height: 0;
    pointer-events: none;
}


.gallery-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    background: $color-purple-20;
    transition: padding $transition-duration-default $transition-timing-bounce;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.gallery-image.selected {
    padding: 10%;
    transition: padding $transition-duration-default $transition-timing-bounce;
}

.gallery-btn-wrapper{

    display: flex;
    align-items: center;
    gap: $spacing-xs;

}

.gallery-btn{

    max-width: 200px;

}

.gallery-image-date{

    color: $color-purple-70;

    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    letter-spacing: -0.72px;

}

.gallery-image-daterange{

    color: $color-purple-70;
    font-family: Space Grotesk;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 92%; /* 44.16px */
    letter-spacing: -2.88px;
    margin-top: 32px;

}

@media (max-width: $vw-mobile) {
    .action-bar {
        .selection-bar-header {
            gap: $spacing-xs;
            
            .selection-bar-heading {
                font-family: inherit;
                font-size: inherit;
                font-weight: 700;
                letter-spacing: inherit;
                line-height: inherit;
            }
        }
    }

    .date-period-section {
        gap: $spacing-sm;
        padding-top: $spacing-md;

        .date-period-header {
            padding: 0 $spacing-md;
            gap: $spacing-2xs;

            .date-period-title {        
                h3 {
                    font-size: 24px;
                    line-height: 100%;
                    letter-spacing: -0.03em;
                }
            }
        }

        .images-container{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}