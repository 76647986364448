@use '../variables' as *;

#settings.page-wrapper #content-wrapper #content {
    flex-direction: column;
  
    .page-content {
      align-items: center;
      padding-top: $spacing-lg;
      padding-bottom: $spacing-lg;
      
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-xl;
        height: auto;
        border: none;
        border-radius: 0;
        background: none;

        .settings-section {
            display: flex;
            width: 100%;
            max-width: 720px;
            flex-direction: column;
        
            .settings-section-wrapper {
                display: flex;
                flex-direction: column;
                gap: $spacing-xs;
            }
        
            &.settings-section-red .settings-section-heading {
                color: var(--red-70, #CE3B3B);
            }
        }
      }
    }
  }

// .settings-wrapper{

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     flex: 1 0 0;
//     align-self: stretch;

// }


// .settings-content{

//     display: flex;
//     max-width: 720px;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: var(--spacing_xl, 48px);

// }