.chip {
    
    display: inline-flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-round, 1000px);
}

.chip-action {
    
    cursor: pointer;
}

.chip-content {

    display: flex;
    padding: 0 var(--spacing-sm, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    flex: 1 0 0;

}

.chip-label{

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: var(--white);

}

.chip svg {

    width: 20px;
    height: 20px;

}

.chip svg path {

    fill: var(--white);

}

/* Green */

.chip-green {

    background: var(--green-50);

}

.chip-secondary.chip-green {
    
    background: var(--green-10);

}

.chip-secondary.chip-green svg path {
    
    fill: var(--green-70);


}
  
.chip-secondary.chip-green .chip-label {
    
    color: var(--green-70);

}

/* Purple */

.chip-purple{

    background: var(--purple-50);

}

.chip-secondary.chip-purple {
    
    background: var(--purple-10);

}

.chip-secondary.chip-purple svg path {
    
    fill: var(--purple-70);

}
  
.chip-secondary.chip-purple .chip-label{
    
    color: var(--purple-70);

}

/* Dark */

.chip-dark{

    background: rgba(255, 255, 255, 0.10);

}

/* Red */

.chip-red{

    background: var(--red-50);

}

/* Chip group */

.chip-group {

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-xs, 8px);
    flex: 1 1 auto;
    flex-wrap: wrap;

}