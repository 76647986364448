@use '../../variables' as *;

.page-title {
    display: flex;
    width: 100%;
    height: 48px;
    padding: $spacing-xs $spacing-sm;
    flex: none;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-xs;
    background: $color-purple-50;
    z-index: 1;

    .page-title-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 100%;
    }

    .page-title-text {
        color: $color-white;
    }
}