@use '../../variables' as *;

.boxed-icon {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--radius-sm, 8px);
    background: var(--white, #FFF);
    transition: all $transition-duration-fast $transition-timing-easeout;
}

.boxed-icon.large {
    width: 96px;
    height: 96px;
    border-radius: var(--radius-md, 16px);
}

.boxed-icon svg{

    width: 32px;
    height: 32px;
    flex-shrink: 0;

}

.icon-idle {
    border: 2px solid var(--purple-20, #E5E5FF);
}

.icon-active {
    border: 2px solid var(--green-40, #18D868);
}

.icon-inactive {
    background: var(--purple-20, #E5E5FF) !important;
    
}

.boxed-icon svg path{

    fill: #9A93FB;

}

.boxed-icon-icon{
    width: 32px;
    height: 32px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.large .boxed-icon-icon {
    width: 64px;
    height: 64px;
    font-size: 64px;
}



/* Specific boxed icons for the courses only */
.boxed-icon-large{
    
    display: flex;
    width: var(--size-96, 96px);
    height: var(--size-96, 96px);
    justify-content: center;
    align-items: center;

}

.boxed-icon-large .boxed-icon-icon{

    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.64px;

}