@use '../../../variables' as *;

.slider-container{
    display: flex;
    height: 20px;
    padding-right: 0px;
    align-items: center;
    flex: 1 0 0;
    border-radius: var(--radius-round, 1000px);
}

.slider {
    display: flex;
    height: 20px;
    padding-right: 0px;
    align-items: center;
    align-self: stretch;
    border-radius: var(--radius-round, 1000px);
    appearance: none;
    width: 100%;
}

/* Slider Track */
.slider::-webkit-slider-runnable-track {
    height: 20px;
    border-radius: var(--radius-round, 1000px);
    border: 0;
}

.slider::-moz-range-track {
    height: 20px;
    border-radius: var(--radius-round, 1000px);
    border: 0;
    background: var(--purple-50, #746CE5);
}

/* Slider Thumb */
.slider::-webkit-slider-thumb {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background: var(--purple-50);
    border: $stroke-width-default $stroke-type-default var(--white, #FFF);
    box-shadow: 0px 0px 0px 1px var(--purple-20, #E5E5FF); 
    appearance: none; 
    border-radius: 50%;
    margin-top: -6px;
}

.slider::-moz-range-thumb {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background: var(--purple-50); 
    border: $stroke-width-default $stroke-type-default var(--white, #FFF); 
    box-shadow: 0px 0px 0px #E5E5FF; 
    appearance: none; 
    border-radius: 50%;
    margin-top: -6px;
}



/* Inactive Slider Thumb */
.slider-active::-webkit-slider-thumb {
    background: var(--purple-20, #E5E5FF); 
}

.slider-active::-moz-range-thumb {
    background: var(--purple-20, #E5E5FF); 
}



// Touchscreen styles
@media (max-width: $vw-tablet) {
    .touchscreen {
        .slider::-webkit-slider-thumb {
            width: 32px;
            height: 32px;
            margin-top: -6px;
        }
        
        .slider::-moz-range-thumb {
            width: 32px;
            height: 32px;
            margin-top: -6px;
        }
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    .slider::-webkit-slider-thumb {
        width: 32px;
        height: 32px;
        margin-top: -6px;
    }
    
    .slider::-moz-range-thumb {
        width: 32px;
        height: 32px;
        margin-top: -6px;
    }
}