@use '../../../variables' as *;

// Media viewer
.media-viewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    background: $color-black;
    z-index: 999;

    .action-bar {
        padding: $spacing-md;

        &.bottom {
            justify-content: center;
        }
    }

    .media-viewer-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        overflow: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

// Timelapse progress bar
.progress-bar-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $spacing-md;
    max-width: 800px;

    .timelapse-button {
        min-width: 48px;
        cursor: pointer;
    }
    
    .progress-bar {
        display: flex;
        width: 100%;
        height: 8px;
        gap: $stroke-width-default;
        position: relative;
        cursor: pointer;
        
        .progress-segment {
            flex: 1;
            transition: left $transition-duration-default $transition-timing-easeout;
            background: rgba($color-white, .3);

            &:first-of-type {
                border-radius: $radius-round 0 0 $radius-round;
            }

            &:last-of-type {
                border-radius: 0 $radius-round $radius-round 0;
            }

            &.filled {
                background: var(--green-50, #00B868);
            }
        }

        .progress-handle {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            transition: left $transition-duration-default $transition-timing-easeout; 
        }
    }
}


// Touchscreen
@media (max-width: $vw-tablet) {
    .touchscreen {
        .progress-bar-container .progress-bar .progress-handle {
            width: 32px;
            height: 32px;
        }
    }
}


// Mobile
@media (max-width: $vw-mobile) {
    .media-viewer {
        background: #000000;
        
        .action-bar {
            padding: $spacing-sm;
        }
    }

    .progress-bar-container .progress-bar .progress-handle {
        width: 32px;
        height: 32px;
    }
}