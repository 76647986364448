@use '../../variables' as *;

.farm-date-wrapper{
    display: flex;
    padding: var(--spacing-2-xs, 4px) var(--spacing-sm, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    border-radius: 40px;
    background: var(--white, #FFF);
    z-index: 4;
    transition: all $transition-duration-fast $transition-timing-easeout;
}

.farm-date-emoji{

    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.16px;

}

.farm-date{

    color: var(--purple-70, #655DDA);
    text-align: center;
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;

}

.farm-date-time{

    color: var(--purple-70, #655DDA);
    text-align: center;

    /* body/small/regular */
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;

}

.date-image-png{

    border-radius: 100%;

}

.hidden{

    visibility: hidden;

}