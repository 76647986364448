@use '../variables' as *;

/* Laptop styles */

#navbar {
    background: var(--purple-20, #E5E5FF);
    display: flex;
    width: 220px;
    min-width: 220px;
    padding: var(--spacing-md, 24px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--spacing-md, 24px);
    align-self: stretch;
    overflow: auto;
    position: relative;
    transition: all $transition-duration-default $transition-timing-bounce;
    z-index: 100;

    /* Collapser */
    .navbar-collapser {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        border-right: $stroke-width-default $stroke-type-default transparent;
        border-left: $stroke-width-default $stroke-type-default transparent;

        svg {
            fill: var(--purple-50);
            opacity: 0;
            rotate: 0deg;
        }

        &:hover {
            transition: none;
            border-right-color: var(--purple-40);
            border-left-color: transparent;
            transition: border $transition-duration-fast $transition-timing-easeout;

            svg {
                opacity: 1;
                transform: translateX(-4px);
                transition: transform $transition-duration-fast $transition-timing-easeout,  opacity $transition-duration-fast $transition-timing-easeout;
            }
        }
    }

    /* Profile */
    .navbar-profile {
        padding: var(--spacing-sm, 16px);
        margin-bottom: var(--spacing-md, 24px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xs, 8px);
        align-self: stretch;
        border-radius: var(--radius-md, 16px);
        background: var(--purple-10, #F0F0FF);
        overflow: hidden;
        transition: all $transition-duration-default $transition-timing-bounce;
    
        .navbar-profile-avatar {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--white);
            border-radius: var(--radius-sm, 8px);
            border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
            transition: all $transition-duration-default $transition-timing-bounce;
            overflow: hidden;

            .bitmap-image,
            #logo-icon {
                width: 100%;
                height: 100%;
                transition: all $transition-duration-default $transition-timing-bounce;
            }
            
            #logo-icon {
                padding: var(--spacing-xs, 8px);
            }
        }

        .navbar-profile-text {
            justify-content: flex-start;
            gap: 4px;
            min-width: 140px;
            transition: all $transition-duration-default $transition-timing-bounce;
            opacity: 1;
            max-height: 120px;
            pointer-events: all;

            .tinyfarm-name {
                font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
                font-size: 21px;
                font-weight: 700;
                line-height: 100%; 
                letter-spacing: -0.03em;
                align-self: stretch;
                text-align: center; 
            }
            
            time {
                color: var(--grey-70);
                margin-top: 4px;
            }
        }
    }

    /* Nav-links */
    .nav-link-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-xs, 8px);
        align-self: stretch;

        .nav-link,
        .language-selector {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            // justify-content: flex-start;
            position: relative;
            width: 100%;
            transition: all $transition-duration-default $transition-timing-bounce;

            .btn {
                width: 100%;
                align-items: flex-start;
                transition: all $transition-duration-default $transition-timing-bounce;

                .btn-content {
                    justify-content: flex-start;
                    .btn-label,
                    .btn-icon-last {
                        opacity: 1;
                        transition: all $transition-duration-default $transition-timing-bounce;
                    }                   
                }
            }
        }

        &.admin-links{
            padding-top: var(--spacing-sm, 16px);
            border-top: $stroke-width-default $stroke-type-default var(--purple-40, #9A93FB);
        }
    }

    /* Collapsed */
    &.collapsed {
        width: 80px;
        min-width: 80px;
        padding: var(--spacing-md, 24px) var(--spacing-sm, 16px);
        overflow: visible;
    
        .navbar-profile {
            padding: 0;
            gap: 0;
            border-radius: var(--radius-sm, 8px);

            .navbar-profile-avatar {
                width: 48px;
                height: 48px;
            }

            .navbar-profile-text {
                opacity: 0;
                max-height: 0;
                pointer-events: none;
            }
        }
        
        .nav-link-list {
            .nav-link,
            .language-selector {
                width: 40px;

                .btn {
                    width: 40px;

                    .btn-content {
                        padding: 0 10px;

                        .btn-label,
                        .btn-icon-last {
                            opacity: 0;
                        }   
                    }
                }
            }
        }

        .navbar-collapser {
            left: 100%;
            justify-content: flex-start;
            cursor: pointer;
    
            &:hover {
                border-left-color: var(--purple-40);
                border-right-color: transparent;
            }
    
            svg {
                rotate: 180deg;
            }
        }

        .tooltip-container .tooltip {
            display: flex;
        }
    }
}

#navbar-mobile {
    display: none;
}

/* Tablet styles */

@media (max-width: $vw-tablet) {
    #navbar {
        width: 80px;
        min-width: 80px;
        padding: var(--spacing-md, 24px) var(--spacing-sm, 16px);
        overflow: visible;
    
        /* Collapser */
        .navbar-collapser {
            display: none;
        }
        
        /* Profile */
        .navbar-profile {
            padding: 0;
            gap: 0;
            border-radius: var(--radius-sm, 8px);
    
            .navbar-profile-avatar {
                width: 48px;
                height: 48px;
            }
            
            .navbar-profile-text {
                opacity: 0;
                max-height: 0;
                pointer-events: none;
            }
        }
        
        /* Nav-link list */
        .nav-link-list {    
            .nav-link,
            .language-selector {
                width: 40px;
    
                .btn {
                    width: 40px;
    
                    .btn-content {
                        padding: 0 10px;
    
                        .btn-label,
                        .btn-icon-last {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        .tooltip-container .tooltip {
            display: flex;
        }
    }
}

/* Mobile styles */

@media (max-width: $vw-mobile) {
    #navbar {
        display: none;
    }
    #navbar-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-height: $height-navbar-mobile;
        justify-content: flex-start;
        align-items: flex-start;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        background: var(--purple-50);
        transition: all $transition-duration-fast $transition-timing-easeout;
        overflow: hidden;
    
        .navbar-profile {
            height: 72px;
            padding: var(--spacing-sm, 16px);
            background: var(--purple-70);
            border-bottom: $stroke-width-default $stroke-type-default var(--purple-50);
            
            .navbar-profile-avatar {
                width: 40px;
                min-width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--white);
                border-radius: var(--radius-sm, 8px);
                border: $stroke-width-default $stroke-type-default var(--purple-50);
                overflow: hidden;
                box-sizing: content-box;
    
                .bitmap-image,
                #logo-icon {
                    width: 100%;
                    height: 100%;
                    transition: all $transition-duration-fast $transition-timing-easeout;
                }
                
                #logo-icon {
                    padding: var(--spacing-2xs, 4px);
                }
            }
        
            .navbar-profile-text {
                color: var(--white);

                .tinyfarm-name {
                    line-height: 100%;
                    margin-bottom: 4px;
                }

                .tinyfarm-id {
                    line-height: 100%;
                }
            }
    
            .language-selector {
                width: auto;
    
                .btn-icon-first {
                    display: none;
                }
            }
    
            .menu-button {
                width: 32px;
                height: 32px;
                fill: $color-white;
                cursor: pointer;
            }
        }
    
        .nav-link-menu {
            width: 100%;
            transition: all $transition-duration-fast $transition-timing-easeout;
            overflow: auto;
    
            .nav-link-list {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
                padding: var(--spacing-xs, 8px) 0;
                background-color: var(--purple-50);
                border-bottom: $stroke-width-default $stroke-type-default var(--purple-70);
    
                &:last-of-type {
                    border-bottom: 0;
                }
    
                .btn {
                    width: 100%;
                    height: 48px;
                    border-radius: none;
        
                    .btn-content {
                        width: 100%;
                        padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
                        justify-content: flex-start;
                        
                        .btn-label {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    
        &.nav-open {
            height: 100%;
            max-height: 100%;
        }
    }
}

/* Touchscreen */

@media (max-width: $vw-tablet) {
    .touchscreen #navbar {
        overflow: visible;
        
        .nav-link-list {
            .nav-link,
            .language-selector,
            .nav-link .btn,
            .language-selector .btn {
                height: 48px;
                width: 48px;

                .btn-content {
                    padding: 0 14px;
                }
            }
            
            .language-selector {
                .tooltip-container .tooltip {
                    display: none;
                }
            }
        }
    }
}

/* Profile tooltip */
#navbar .tooltip .navbar-profile {
    margin-bottom: 0;
    gap: $spacing-xs;
    padding: $spacing-sm $spacing-sm;
    border-radius: 0;
    background: none;

    .navbar-profile-avatar {
        width: 64px;
        height: 64px;
        pointer-events: none;
        border-color: $color-purple-10;
    }

    .navbar-profile-text {
        align-items: center;
        opacity: 1;
        max-height: none;

        .tinyfarm-name {
            white-space: nowrap;
        }
    }
}