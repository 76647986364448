.bold {
    font-weight: bold;
  }
  
.list-item {
  margin-left: 1em;
  list-style-type: disc;
}

.lesson-factoid-wrapper{
  padding: var(--spacing-sm, 16px);
  border-radius: var(--radius-md, 16px);
  gap: 16px;
  align-self: stretch;
}

.lesson-factoid-title {

  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.72px;

}

.factoid-green{

  background: var(--green-10, #DEFFE1);

}

.factoid-green .lesson-factoid-header{

  color: var(--green-70, #009454);

}

.factoid-purple .lesson-factoid-header{

  color: var(--purple-70, #5D55D8);

}

.factoid-purple{

  background: var(--purple-10, #F0F0FF);

}

.lesson-link{

  color: blue;

}

.multiple-images-container {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
}

.multiple-image-wrapper img {
  max-width: 95%;
  height: auto;
  flex: 1;
  margin: 0 5px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th, .table-container td {
  border: 1px solid black;
  padding: 8px 12px;
  text-align: left;
}

.table-container thead th {
  background-color: #f2f2f2;
}
