@use '../../../variables' as *;

.control-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: $stroke-width-default $stroke-type-default $color-purple-10;
    padding: $spacing-md;
    gap: $spacing-sm;

    .control-header {
        display: flex;
        align-items: center;
        gap: $spacing-sm;

        .control-selectors {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: $spacing-2xs;
            flex-grow: 1;

            .control-title {
                display: flex;
                align-items: center; 
                justify-content: flex-start;  
                gap: $spacing-2xs;
            }
        }
    }

    .selectors {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-xs;
    }

    &:last-of-type .dropdown-menu {
        top: auto;
        bottom: 100%;
    }
}
