@use '../../../variables' as *;

/* tinyfarm frame */

.farm-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 280px;
  min-width: 280px;
  max-height: 320px;
  max-width: 320px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.farm-container {
  position: relative;
  // width: auto;
  // height: 100%;
  width: 100%;
  height: auto;
}


/* .farm-container::before {
  content: "";
  float: left;
  width: 1px;
  margin-left: -1px;
  padding-top: 100%;
} */

.farm-foreground {
  width: 87%;
  height: 87%;
  padding: var(--spacing-xs, 8px);
  position: absolute;
  top: 6.5%;
  left: 6.5%;
  border: 2px solid var(--purple-50);
  border-radius: 4px;
  z-index: 1;
}

.farm-foreground .info-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 4;
}

.farm-foreground .info-icon path {
  fill: var(--white);
}

.farm-container .loading {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: var(--black);
  color: var(--white);
  padding: 24px;
  gap: 8px;
}

.farm-container .loading .MuiCircularProgress-root {
  color: var(--white) !important;
}

.pictures-empty h4 {
  color: var(--white);
  margin-bottom: 0;
}

.virtual-farm-svg {
  position: absolute;
  width: 213%;
  height: 213%;
  left: -13.2%;
  bottom: -28.5%;
  pointer-events: none;
}

.current-image{
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: all $transition-duration-fast $transition-timing-easeout;
  animation: picture-in $transition-duration-fast $transition-timing-easeout;
}

.farm-buttons {
  z-index: 4;
  transition: all $transition-duration-fast $transition-timing-easeout;
  animation: picture-in $transition-duration-fast $transition-timing-easeout;
}

.farm-buttons.flash-on {
  opacity: 0;
  pointer-events: none;
}

.farm-camera-button, .farm-play-button{
  max-width: 60px;
  cursor: pointer;
}

.current-image.flash-on {
  opacity: 0;
  transition: all 0ms $transition-timing-easeout;
  transition-delay: 250ms;
}

.flash {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $color-white;
  pointer-events: none;
  opacity: 0;
}

.flash.flash-on {
  animation: flash 500ms $transition-timing-easeout;
}

@keyframes flash {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes picture-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

// @media (max-width: $vw-mobile) {
//   .farm-wrapper {
//     flex-direction: column;
//   }
  
//   .farm-container {
//     width: 100%;
//     height: auto;

//     .aspectratio-svg {
//       width: 100%;
//       height: auto;
//     }
//   }
// }

@media (max-width: $vw-mobile) {
  .farm-wrapper {
    max-height: 416px;
    max-width: 416px;
  }
}