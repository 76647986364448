@use '../../../variables' as *;

.course-block-wrapper {
    display: flex;
    max-width: 720px;
    align-items: flex-start;
    gap: $spacing-sm;
    padding: $spacing-md;
    align-items: center;
    justify-content: center;
    border-radius: $radius-md;
    border: $stroke-width-default $stroke-type-default $color-purple-20;
    background: $color-white;
    cursor: pointer;

    &.course-block-green {
        background: $color-green-10;
    }
    
    &.course-block-purple {
        background: $color-purple-10;
    }
    
    .course-block-content {
        display: flex;
        padding: $spacing-xs 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $spacing-xs;
        flex: 1 0;
    }
    
    .course-block-chevron {
        width: 40px;
        height: 40px;
        fill: var(--purple-50);
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    .course-block-wrapper {
        max-width: 400px; // possibly remove when CSS grid is applied
        flex-direction: column;
        align-items: flex-start;
        cursor: default;

        .btn {
            width: 100%;
        }
    }
}