@use '../../variables' as *;

.modal-container {
    width: 100vw;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    padding: $spacing-md;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .modal-blanket {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: $color-purple-90;
        transition: all $transition-duration-fast $transition-timing-easeout;
    }

    .modal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-md;
        position: relative;
        top: 10%;
        height: auto;
        max-height: calc(100% + $spacing-xs);
        width: 100%;
        max-width: 600px;
        border: $stroke-width-default $stroke-type-default $color-purple-40;
        border-radius: $radius-md;
        background: $color-white;
        box-shadow: 0px 0px 40px 0px rgba(61, 57, 115, 0.50);
        transition: all $transition-duration-default $transition-timing-bounce;
        overflow: hidden;
        opacity: 0;

        &.modal-large {
            max-width: 820px;

            .action-buttons {
                width: 100%;
                align-items: center;
            }
        }

        .modal-close {
            top: $spacing-xs;
            right: $spacing-xs;
            position: absolute;
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            gap: $spacing-lg;
            overflow: auto;
            padding: $spacing-xl;
            width: 100%;

            .modal-heading {
                margin-bottom: $spacing-sm;
            }

            .modal-howto {
                background: $color-purple-10;
                display: flex;
                padding: $spacing-md;
                flex-direction: column;
                align-items: flex-start;
                border-radius: $radius-sm;

                ul,
                ol {
                    margin: 0;
                }
                
                .modal-howto-note {
                    color: $color-purple-70;
                }
            }

            .action-buttons .btn {
                width: 100%;
                max-width: 256px;
            }
        }
    }

    &.animate {
        .modal-blanket {
            opacity: $opacity-modal-blanket;
        }
        .modal {
            top: 0;
            opacity: 1;
        }
    }

    &.closing {
        .modal-blanket {
            opacity: 0;
        }
        .modal {
            top: 10%;
            opacity: 0;
        }
    }
}

// Mobile styles

@media (max-width: $vw-mobile) {
    .modal-container {
        position: absolute;
        padding: 0;
        align-items: flex-end;

        .modal {
            max-width: 430px !important;
            top: unset !important;
            bottom: -20%;
            padding-bottom: $spacing-xs;
            border-bottom: none;
            border-radius: $radius-md $radius-md 0 0;
            opacity: 1;

            .modal-content {
                padding: $spacing-md;
                gap: $spacing-sm;

                .modal-heading {
                    padding-right: $spacing-lg;
                }

                .action-buttons {
                    flex-direction: column-reverse;
                    
                    .btn {
                        max-width: none;
                    }
                }
            }
        }

        &.animate {
            .modal {
                bottom: -$spacing-xs;
            }
        }

        &.closing {
            .modal {
                bottom: -20%;
            }
        }
    }
}