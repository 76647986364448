@use '../../../variables' as *;

/* In the modal */

.modal .program-running-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: $spacing-md;
  border-radius: $radius-md;
  border: $stroke-width-default $stroke-type-default $color-green-40;
  background: $color-green-10;
  overflow-y: scroll;
  min-height: 296px;

  * {
    cursor: default !important;
  }
}

@media (max-width: $vw-mobile) {
  #duplicate {
    display: none;
  }
  
  .modal .program-running-preview {
    min-height: 192px;
    padding: $spacing-sm;
  }
}