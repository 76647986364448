@use '../../variables' as *;

/* Dropdown */
.dropdown {
    display: flex;
    position: relative; 
    cursor: pointer;
    background: var(--white, #FFF);
    border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
    border-radius: var(--spacing-xs, 8px);
}

.dropdown-menu {
    max-height: 180px;
    min-width: calc(100% + 4px);
    flex-direction: column;
    background: var(--white, #FFF);
    border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
    border-radius: var(--spacing-xs, 8px);
    display: inline-flex;
    transition: 0.3s ease-in-out; 
    position: absolute;
    top: 100%;
    z-index: 100;
    margin-left: -2px;
    overflow: auto;
    padding: 8px 0;
    cursor: default;
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}

.dropdown-item {

    display: flex;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-xs, 8px);
    cursor: pointer;
    overflow: hidden;

}

#dropdown-colors .dropdown-menu:not(.dropdown-item-selected) {
    min-width: min-content;
}

#dropdown-colors .dropdown-item-selected .dropdown-label {
    display: none;
}

.small-selected {
    min-width: auto;
}

.dropdown-svg {
    width: 20px;
    height: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-svg svg {
    max-height: 20px;
}

.dropdown-chevron-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    fill: var(--purple-70, #655DDA);
}


.dropdown-label {
    color: var(--black, #1E322B);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 20px;
}

.dropdown-item-selected .dropdown-label {
    font-weight: 700;
    color: var(--purple-70, #655DDA);
}

.dropdown-item:not(.dropdown-item-selected):hover {
    background: var(--purple-10, #F0F0FF);
}

/* Mobile dropdown */

.dropdown-menu-mobile {
    &.modal-container .modal .modal-content .action-buttons {
        flex-direction: column;

        .btn .btn-content {
            justify-content: flex-start;
        }
    }
}

/* Number input */

.number-input, .static-input {
    display: flex;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 4px;
}

.number-input-field, .number-input-unit, .static-input-field {
    color: var(--purple-70, #655DDA);
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.16px;
    border: none;
    outline: none;
    background: none;
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.number-input-field {
    max-width: 30px;
    text-align: center;
}

.number-input-field::-webkit-outer-spin-button,
.number-input-field::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

.number-input-unit {
    display: flex;
    padding: 0px var(--spacing_2xs, 4px);
    align-items: center;
    border-radius: var(--radius_xs, 4px);
    background: var(--purple-10, #F0F0FF);
}

.combined-selector {
    display: flex;
    align-items: flex-start;
    border: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
    border-radius: 8px;
    height: 40px;
}

.combined-selector > *:not(:last-child):not(:only-child) {
    border-right: $stroke-width-default $stroke-type-default var(--purple-20, #E5E5FF);
}

.selector-item {
    height: 100%;
}

.selector-item .dropdown {
    border: none;
}

.btn-pump {
    flex-grow: 0;
    justify-self: flex-end;
}

// Touchscreen styles
@media (max-width: $vw-tablet) {
    .touchscreen {
        .dropdown-item,
        .static-input,
        .number-input {
            height: 44px;
        }
        .combined-selector {
            height: 48px;
        }

        .dropdown-label {
            font-size: 16px;
        }
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    .dropdown {
        overflow: hidden;

        &.mobile-fill {
            width: 100%;
            
            .dropdown-item {
                width: 100%;

                .dropdown-label {
                    width: 100%;
                }
            }
        }

        .dropdown-item,
        .static-input,
        .number-input {
            height: 44px;
        }

        .dropdown-label {
            font-size: 16px;
        }
    }

    .combined-selector {
        width: 100%;
        height: 44px;
        overflow: hidden;

        .selector-item {
            &.mobile-fill {
                width: 100%;
                overflow: hidden;
            }

            .dropdown-item {
                height: 40px;
            }
        }
    }
}