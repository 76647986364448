@use '../../../variables' as *;

@keyframes sensordata {
    0% { color: $color-green-40; } 
    90% { color: $color-green-40; } 
    100% { color: $color-purple-50; } 
}

.sensor-block {
    display: flex;
    align-items: center;
    gap: $spacing-sm;

    .sensor-info {
        display: flex;
        flex-direction: column;
        gap: $spacing-2xs;
        
        .sensor-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: $spacing-2xs;
        }

        .sensor-value {
            white-space: nowrap;

            .text-value,
            .text-unit {
                white-space: nowrap;
                transition: all $transition-duration-fast $transition-timing-easeout;
            }
        }
    }

    &.sensor-block-idle {
        .text-value,
        .text-unit {
            color: $color-purple-70;
        }
    }

    &.sensor-block-active {
        .text-value,
        .text-unit {
            color: $color-green-40;
        }
    }

    &.sensor-block-inactive .text-value {
        color: $color-purple-40;
    }
}


