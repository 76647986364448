@use 'variables' as *;
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Space Grotesk font */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

/* Schibsted Grotesk font */
@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {

  /* Colors */
    /* Base */
    --white: #ffffff;
    --grey-10: #ECEEED;
    --grey-20: #DCDFDE;
    --grey-40: #A1AAA7;
    --grey-50: #8C9793;
    --grey-70: #66706D;
    --grey-90: #3A403E;
    --black: #1E322B;

    /* Green */
    --green-10: #DEFFE1;
    --green-20: #C7FACC;
    --green-40: #18D868;
    --green-50: #00B868;
    --green-70: #009454;
    --green-90: #005229;

    /* Purple */
    --purple-10:#F0F0FF;
    --purple-20: #E5E5FF;
    --purple-40: #9A93FB;
    --purple-50: #7870E6;
    --purple-70: #5D55D8;
    --purple-90: #3D3973;

    /* Red */
    --red-10: #FFEBEB;
    --red-20: #FFDBDB;
    --red-40: #FF7070;
    --red-50: #E15656;
    --red-70: #CE3B3B;
    --red-90: #6F2525;
  
  /* Transitions */
    --t-duration-default: 100ms;
    --t-duration-modal: 300ms;
    --t-timing-default: ease-out;
  
}

/* Base */

* {
  font-family: "Schibsted Grotesk", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.01em;
}

html {
  height: 100%;
}

body {
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

/* Required in order to have custom sliders and thumbs */
input[type=range] {
  -webkit-appearance: none; 
  appearance: none;
}

/* 
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
} */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Text styles */

p, .p, ul, ol {
  margin: 0 0 12px;
}

p.small, .small, .small * {
  font-size: 14px;
}

p.xsmall, .xsmall, .xsmall * {
  font-size: 12px;
}

ul li {
  margin: 0px;
}

ol {
  list-style: auto;
}

ol li {
  margin-left: 26px;
  margin-bottom: 8px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: var(--purple-70);
}

h1, .h1, h2, .h2 {
  font-size: 48px;
  line-height: 92%;
  letter-spacing: -0.06em;
  margin: 0 0 8px;
}

h3, .h3 {
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin: 0 0 24px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.03em;
  margin: 0 0 16px;
}

h5, .h5 {
  font-style: normal;
  font-weight: 700;
  color: var(--purple-50);
  margin: 0 0 8px;
}

h6, .h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--purple-50);
  margin: 8px 0 8px;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
p:last-child,
ul:last-child {
  margin-bottom: 0;
}

h6:first-child {
  margin-top: 0px;
}

sub {
  display: inline-block;
  font-family: inherit;
  font-size: .6em !important;
  font-weight: inherit;
  line-height: 0;
  color: inherit;
}

blockquote {
  border-left: $stroke-width-default $stroke-type-default var(--green-40);
  margin: 16px 0 32px;
  padding-left: 32px;
}

blockquote h4 {
  font-family: 'Schibsted Grotesk', Arial, Helvetica, sans-serif;
  line-height: 120%;
  letter-spacing: -0.02em;
}

blockquote cite {
  font-size: 16px;
  font-style: italic;
}

sup {
  display: inline-block;
  font-family: inherit;
  font-size: .6em !important;
  font-weight: inherit;
  line-height: 0;
  color: inherit;
}

.preheader {
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 8px;
}

b, .bold {
  font-weight: 700;
}

.text-white {
  color: $color-white;
}

.text-purple {
  color: $color-purple-70;
}

.text-green {
  color: $color-green-50;
}

.text-placeholder {
  opacity: $opacity-text-placeholder;
}

/* Layout */

.page-wrapper {
  display: flex;
  height: 100%;
  background: var(--purple-10, #F0F0FF);

  #content-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100%;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;

    #content {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 620px;
      overflow-x: hidden;

      .page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: $spacing-md $spacing-lg;
        gap: $spacing-sm;

        .main {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: $radius-md;
          border: $stroke-width-default $stroke-type-default $color-purple-40;
          background: $color-white;
          overflow: auto;
        }
      }
    }
  }
}

.m0 {
  margin-bottom: 0;
}

.aspectratio-svg.width {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.aspectratio-svg.height {
  height: 100%;
  width: auto;
  pointer-events: none;
}

.hide {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
}


/* Action bar */

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--spacing_sm, 16px);

  .action-bar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-sm;
  }
}


/* Mobile styles */

@media (max-width: $vw-mobile) {  
  .page-wrapper {
    flex-direction: column;
    
    #content-wrapper {
      flex-direction: column;
      padding-top: $height-navbar-mobile;

      #content {
        min-height: 0;
        flex-direction: column;

        .page-content {
          padding: $spacing-lg $spacing-sm $spacing-md;

          .main {
            overflow: hidden;
          }
        }
      }
    }
  }

  .action-bar .action-bar-header {
    h4 {
      font-family: inherit;
      font-size: inherit;
      font-weight: 700;
      letter-spacing: inherit;
      line-height: inherit;
    }
  }

  .hide-mobile {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
    visibility: hidden !important;
  }
}

.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-48 {
  gap: 48px;
}

.gap-64 {
  gap: 64px;
}

.gap-96 {
  gap: 96px;
}