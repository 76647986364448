@use '../../variables' as *;

@keyframes fill {
    to {
        transform: translateX(0);
    }
}

.btn {
    display: inline-flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $radius-sm;
    cursor: pointer;

    
    .btn-content {
        display: flex;
        width: 100%;
        min-width: 48px;
        padding: 0 $spacing-sm;
        justify-content: center;
        align-items: center;
        gap: $spacing-xs;
        flex: 1 0;
        z-index: 0;

        .btn-icon-first,
        .btn-icon-last {
            width: 20px;
            height: 20px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn-label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: $spacing-2xs 0;
        }
    
    }

    svg {
        width: 20px;
        height: 20px;
    }
    
    &.btn-disabled {
        opacity: $opacity-disabled;
        pointer-events: none;
    }

    &.btn-primary {
        .btn-label {
            color: $color-white;
        }

        svg:not(.icon-light) path {
            fill: $color-white;
        }

        &.btn-green {
            background: $color-green-50;
        }
    
        &.btn-purple {
            background: $color-purple-50;
        }
    
        &.btn-red {
            background: $color-red-50;
        }
    
        &.btn-dark {
            background: rgba($color-white, 0.15);
        }
    }

    &.btn-secondary {
        &.btn-green {
            background: $color-green-20;

            svg:not(.icon-light) path {
                fill: $color-green-70;
            }

            .btn-label {
                color: $color-green-70;
            }
        }

        &.btn-purple {
            background: $color-purple-20;

            svg:not(.icon-light) path {
                fill: $color-purple-70;
            }

            .btn-label {
                color: $color-purple-70;
            }
        }

        &.btn-red {
            background: $color-red-20;

            svg:not(.icon-light) path {
                fill: $color-red-70;
            }

            .btn-label {
                color: $color-red-70;
            }
        }
    }

    &.btn-small {
        height: 32px;

        .btn-content {
            padding: 0 $spacing-xs;
            gap: $spacing-2xs;

            .btn-icon-first,
            .btn-icon-last {
                width: 16px;
                height: 16px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &.btn-icon,
    &.btn-collapsed {
        width: 40px;
        min-width: 40px;
        align-items: flex-start;

        .btn-content {
            justify-content: flex-start;
            padding: 0 10px;

            .btn-label,
            .btn-icon-last {
                opacity: 0;
            }
        }
    }

    &.btn-pumping {
        background: $color-white;
        border: $stroke-width-default $stroke-type-default $color-green-40;
        position: relative;
        overflow: hidden;
        width: 100%;

        .btn-label {
            color: $color-green-70;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            transform: translateX(-100%);
            background-color: $color-green-20;
            z-index: -1;
        }
    }
}

.button-group {
    display: flex;
    align-items: center;
    gap: $spacing-3xs;
    button {
        border-radius: 0;
    }

    > button:first-of-type,
    a:first-of-type button {
        border-top-left-radius: $radius-sm;
        border-bottom-left-radius: $radius-sm;
    }

    > button:last-of-type,
    a:last-of-type button {
        border-top-right-radius: $radius-sm;
        border-bottom-right-radius: $radius-sm;
    }
}

.btn-selected {
    border: 2px solid #00B868; 
}
  
.action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: $spacing-xs;
}

/* Touchscreen */
.touchscreen {
    .btn.btn-small {
        height: 32px;

        .btn-label {
            font-size: 14px;
        }
    }
}

@media (max-width: $vw-tablet) {
    .touchscreen {
        .btn {
            height: 48px;

            .btn-label {
                font-size: 16px;
            }
            
            &.btn-icon,
            &.btn-collapsed {
                width: 48px;
                min-width: 48px;
                height: 48px;

                .btn-content {
                    justify-content: flex-start;
                    padding: 0 14px;
                }
            }
        }
    }
}

// Mobile styles
@media (max-width: $vw-mobile) {
    .btn {
        height: 48px;

        .btn-label {
            font-size: 16px;
        }

        &.mobile-full {
            width: 100%;
        }
        
        &.btn-icon,
        &.btn-collapsed {
            width: 48px;
            min-width: 48px;
            height: 48px;

            .btn-content {
                justify-content: flex-start;
                padding: 0 14px;
            }
        }
    }
}