@use '../variables' as *;

#analytics {
    .main {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
    
        .chart-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing_sm, 16px);
            flex: 1;
            align-self: stretch;
            min-width: calc(100% + $stroke-width-default);
            border-right: 2px solid #E5E5FF; 
            border-bottom: 2px solid #E5E5FF;
            overflow: hidden;

            .chart-header {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                padding: $spacing-md $spacing-md 0 $spacing-md;
            }
            
            .chart-container {
                width: 100%;
                flex: 1;
                overflow: auto;
                padding-bottom: $spacing-md;

                .chart {
                    width: 100%;
                    min-width: 400px;
                    height: 100%;
                    padding: 0 $spacing-md;
                }
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

@media (max-width: $vw-mobile) {
    #analytics {
        .action-bar {
            flex-direction: column-reverse;
            align-items: flex-start;

            .analytics-date-range {
                font-size: 24px;
                line-height: 100%;
                letter-spacing: -0.03em;
            }
        }
        .main {
            display: flex;
            flex-direction: column;

            .chart-wrapper {
                border-right: 0;

                .chart {
                    min-height: 200px;
                }
            }
        }
    }
}