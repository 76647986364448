.toggle{

    width: 48px;
    border-radius: 40px;
    padding: var(--spacing-2-xs, 4px);

}

.toggle, .toggle-big {
    background: var(--purple-50);
    cursor: pointer;
    min-width: 56px;
    height: 28px;
    padding: var(--spacing-2-xs, 4px);
    border-radius: 1000px;
}

.toggle-big {

    min-width: 64px;
    height: 40px;

}

.toggle-switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 1000px;
    background: var(--white, #FFF);
    width: 20px;
    height: 20px;
    transition: all 100ms ease-out;
    
}

.toggle-big .toggle-switch {
    width: 40px;
    height: 32px;
}

.toggle-on {
    background: var(--green-50, #00B868);
}

.toggle-on .toggle-switch {
    transform: translateX(28px);
}

.toggle-on.toggle-big .toggle-switch {
    transform: translateX(16px);
}

.toggle-switch-handle {
    color: var(--purple-70);
    text-align: center;
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.14px;
}

.toggle-on .toggle-switch-handle {
    color: var(--green-70);
}


/*If toggle is disabled*/

.toggle-disabled {
    opacity: 0.6;
}
  
.toggle-disabled.toggle-on {
background: var(--green-20, #C7FACC);
}

.toggle-disabled:not(.toggle-on) {
background: var(--purple-20, #E5E5FF);
}