@use '../../variables' as *;

.loading {
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: $spacing-md;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-sm;
  
    .MuiCircularProgress-root {
      color: var(--purple-50) !important;
      width: 32px !important;
      height: 32px !important;
    
      circle {
        stroke-linecap: round;
      }
    }
  }