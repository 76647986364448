@use '../../variables' as *;

.tooltip-container {
    position: relative;
    width: 100%;

    .tooltip {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        max-width: 280px;
        border: $stroke-width-default $stroke-type-default transparent;
        border-radius: var(--radius-sm, 8px);
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;

        .tooltip-text {
            display: flex;
            padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-xs, 8px);
            text-align: left;
            
            p {
                white-space: nowrap;
            }
        }

        &.tooltip-white {
            border-color: var(--purple-20);
            background: var(--white);

            .tooltip-text {
                p {
                    color: var(--black);
                }
            }
        }

        &.tooltip-purple {
            border-color: var(--purple-50);
            background: var(--purple-70);

            .tooltip-text {
                p {
                    color: var(--white);
                }
            }
        }

        &.tooltip-top {
            top: auto;
            bottom: calc(100% + 8px);
            left: 50%;
            transform: translateX(-50%);
        }

        &.tooltip-right {
            left: calc(100% + 8px);
            top: 50%;
            transform: translateY(-50%);
        }

        &.tooltip-left {
            left: auto;
            right: calc(100% + 8px);
            top: 50%;
            transform: translateY(-50%);
        }

        &.tooltip-align-top {
            top: 0;
            transform: none;
        }

        &.tooltip-align-bottom {
            top: auto;
            bottom: 0;
            transform: none;
        }

        &.tooltip-align-left {
            left: 0;
            transform: none;
        }

        &.tooltip-align-right {
            left: auto;
            right: 0;
            transform: none;
        }
    }
}