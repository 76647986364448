@use '../../variables' as *;

@keyframes slide-up {
  from {
    bottom: 0px;
    opacity: 0;
  }
  to {
    bottom: 16px;
    opacity: 1;
  }
}

@keyframes slide-up-mobile {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 48px;
    opacity: 1;
  }
}

.toast {
  position: absolute;
  bottom: 16px;
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 50;
  border-radius: var(--radius-sm, 8px);
  background: var(--purple-70);
  display: inline-flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  padding: var(--spacing_2xs, 4px);
  animation: slide-up $transition-duration-default $transition-timing-bounce;

  .toast-message {
    width: 100%;
    justify-content: flex-start;
    min-width: 0;
  }

  .button-group > button:first-of-type {
    border-top-left-radius: $radius-xs;
    border-bottom-left-radius: $radius-xs;
  }

  .button-group > button:last-of-type {
    border-top-right-radius: $radius-xs;
    border-bottom-right-radius: $radius-xs;
  }
}

.toast.page-full {
  margin-left: 0;
}

.toast-message {
    display: flex;
    padding: 0px var(--spacing-sm, 8px);
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;
}

.toast-svg-icon {
    width: 50px; 
    height: auto; 
    display: block;
}

.toast-label {
  color: var(--white);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.toast-right {

    display: flex;
    padding: 0px var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-sm, 16px);
    align-self: stretch;
}

.toast-purple {
  background: var(--purple-70);
}

.toast-green {
  background: var(--green-70);
}

.toast-red{
  background: var(--red-70, #CE3B3B);
}

.toast-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 32px;
  padding: var(--spacing_2xs, 4px);
  border-radius: var(--radius_xs, 4px);
  background: var(--white);
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  
    * {
      cursor: pointer !important;  
    }
  }
}

.wrong-pin{

  z-index: 9999;

}

.program-thumbnail{

  width: 100%;
  min-width: 100%;
  border-radius: var(--radius-sm, 8px);
  border: 2px solid var(--purple-20, #E5E5FF);
  background: var(--purple-10, #F0F0FF);
  position: relative;
  height: 0;
  padding-top: calc(9/16 * 100%);
  overflow: hidden;

}

.program-thumbnail svg {
  max-height: 72%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  width: 100%;
  height: 100%;
}

@media (max-width: $vw-mobile) {
  .toast {
    width: 100%;
    height: 48px;
    position: initial;
    flex: none;
    align-items: flex-start;
    gap: $spacing-2xs;
    padding: $spacing-xs;
    transform: none;
    animation: slide-up-mobile 300ms cubic-bezier(0.18, 0.89, 0.44, 1.16);
    border-radius: 0;

    .button-group > button:first-of-type {
      border-top-left-radius: $radius-sm;
      border-bottom-left-radius: $radius-sm;
    }
    .button-group > button:last-of-type {
      border-top-right-radius: $radius-sm;
      border-bottom-right-radius: $radius-sm;
    }
  }
}